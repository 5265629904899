import React, { Fragment } from "react";
import { theme, websiteColors } from "./../themes/Theme";
import HomeNav from './Home/HomeNav'


const Error404 = () => {
	return (
		<Fragment>
			<HomeNav />
			<div className="overlay">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error_section px-0">
						<span className="error_code">404</span>
						<div className="error_message">
							Page Not Found
						</div>
						<div className="mt-5 mb-5 w-100">
							<img src="/img/error404.webp" alt="" className="error-section-image" />
						</div>
						<div className="error-button my-4">
							<a href="/" className="error_dec">Go To Home</a>
						</div>
					</div>
				</div>
			</div>
			<style jsx>{`
				.overlay {
					font-family: "Figtree";
				}

				.error_section {
					display: flex;
					flex-direction: column;
					margin-top: 80px;
					align-items: center;
				}

				.error-section-image {
					height: 94px;
					width: 100%;
				}

				.error_section-image {
					align-items: center;
				}
				.error_placehlder {
					position: relative;
				}
				.error_code {
					font-weight: 700;
					font-size: 150px;
					line-height: 150px;
					color: ${websiteColors[theme].primaryWebsiteTheme};
					display: block;
					
				}

				.error_message {
					display: block;
					font-weight: 600;
					font-size: 40px;
					line-height: 60px;
					color: ${websiteColors[theme].primaryWebsiteTheme};
				}
				.error-button{
					background: ${websiteColors[theme].primaryWebsiteTheme};
					border-radius: 4px;
					border: 1px solid ${websiteColors[theme].primaryWebsiteTheme};
					box-shadow: 0px 1px 2px 0px #1018280D;
					height: 48px;
					width: 130px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.error_dec {
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					color: #ffffff;
					text-decoration: none;
				}
				.error_dec:hover {
					color: #ffffff;
					text-decoration: none;

				}
				@media screen and (max-width: 992px) {
					.error-section-image {
						object-fit: cover;
				    }

				}
				@media screen and (max-width: 576px) {
					.error_section{
						margin-top: 40px;
					}
					.error_code {
				    	font-size: 100px;
				    	line-height: 100px;
				    }
					.error_message {
					   font-size: 32px;
					   line-height: 48px;
				    }
					.error-section-image {
					    height: 46px;
						object-fit: cover;
				    }
				}
			`}</style>
		</Fragment>
	);
};

export default Error404;
